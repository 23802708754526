var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1 bgFFF paddingLR20" }, [
    _c(
      "div",
      { staticClass: "button" },
      [
        _c(
          "el-button",
          { staticStyle: { width: "88px" }, on: { click: _vm.cancleClick } },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "baseInfo" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("info-table", {
            attrs: {
              dataValue: _vm.detail,
              nameStyle: { width: "150px" },
              valueStyle: { width: "calc(100% - 150px)" },
              num: 3,
              tableData: _vm.tableData,
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auditRecord" },
      [
        _vm._m(1),
        _c("info-table", {
          attrs: {
            nameStyle: { width: "150px" },
            valueStyle: { width: "calc(100% - 150px)" },
            num: 1,
            dataValue: _vm.detail,
            tableData: _vm.auditRecordTable,
          },
        }),
        _c("pic-list", { attrs: { pics: _vm.pics } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "auditHandle" },
      [
        _vm._m(2),
        _c("info-table", {
          attrs: {
            dataValue: _vm.detail,
            nameStyle: { width: "150px" },
            valueStyle: { width: "calc(100% - 150px)" },
            num: 1,
            tableData: _vm.auditHandleTable,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v("基本信息 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v("稽查记录 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v("稽查处理 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
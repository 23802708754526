<template>
  <div class="page1 bgFFF paddingLR20">
    <div class='button'>
          <el-button  @click="cancleClick" style="width: 88px;">返回</el-button>
    </div>
    <!--主体内容-->
    <div class='baseInfo'>
      <h2 class="title">
        <div class="title_icon"></div>基本信息
      </h2>
      <div class="content">
        <info-table :dataValue='detail'  :nameStyle="{width: '150px'}" :valueStyle="{width: 'calc(100% - 150px)'}"  :num='3' :tableData='tableData'></info-table>
      </div>
    </div>
    <div class='auditRecord'>
       <h2 class="title">
        <div class="title_icon"></div>稽查记录
       </h2>
       <info-table :nameStyle="{width: '150px'}" :valueStyle="{width: 'calc(100% - 150px)'}" :num='1' :dataValue='detail' :tableData='auditRecordTable'></info-table>
       <pic-list :pics='pics'></pic-list>
    </div>
    <div class='auditHandle'>
      <h2 class="title">
        <div class="title_icon"></div>稽查处理
       </h2>
        <info-table :dataValue='detail' :nameStyle="{width: '150px'}" :valueStyle="{width: 'calc(100% - 150px)'}" :num='1' :tableData='auditHandleTable'>
        </info-table>
    </div>

  </div>
</template>

<script>
import infoTable from '@/components/infoTable/infoTable';
import {confirmFn} from '@/common/js/public.js'
import picList from './picList'
export default {
    name: 'auditCheck',
    data () {
      return {
        pics: [],
        detail: {},
        checkReson: 'hahaahah',
        auditHandleTable: [{
          name: '处理结果',
          key: 'result'
        }],
         auditRecordTable: [{
          name: '违规说明',
          key: 'remark'
        }],
         tableData: [
        {
          name: '记录编号',
          key: 'inspectionCode'
        },
        {
          name: '违规人员姓名',
          key: 'violatorsName'
        },
        {
          name: '违规类型',
          key: 'typeStr'
        },
        {
          name: '道路名称',
          key: 'parkName'
        },
        {
          name: '上报时间',
          key: 'reportTime'
        },
        {
          name: '状态',
          key: 'status',
          format: (val) => {
            let text = ''
            if (val == 0) { text = '待审批' }
            if (val == 1) { text = '已撤回' }
            if (val == 2) { text = '驳回' }
            if (val == 3) { text = '审批通过' }
            // return `
            //   <span style='display: inline-block;background: red;line-height: 25px; padding: 0 5px;border-radius: 5px;color: white'>
            //     ${text}
            //   </span>
            // `
            return `${text}`
          }
        },
        {
          name: '稽查员姓名',
          key: 'inspectorName'
        },
        {
          name: '稽查员联系电话',
          key: 'inspectorMobileNo'
        },
        {
          name: '所属运营商',
          key: 'operationName'
        }
      ]
      }
    },
    methods: {
      cancleClick() {
        this.$router.go(-1);
      },
      checkResult() {
        if (this.checkReson) {
          return true
        }
        return false
      },
      checkSubmit(status, cb) {
        this.$axios.post(`/acb/2.0/inspection/audit/${this.$route.query.inspectionId}`, {
          data: {result: this.checkReson, status}
        }).then((res) => {
          // this.data = res.value.list[0];
          this.$router.go(-1);
          if (cb) cb();
        })
      },
      getDetail() {
        this.$axios.get(`/acb/2.0/inspection/getById/${this.$route.query.inspectionId}`).then((res) => {
          this.detail = res.value;
          this.checkReson = res.value.result;
          this.pics = res.value.pictureMap;
        })
      },
      sureClick() {
        if (this.checkResult()) {
           confirmFn({tipText: '确定处理当前稽查结果?',
            sucFn: (cb) => {
              this.checkSubmit(3, cb)
            }
          })
        } else {
          confirmFn({tipText: '请先进行处理结果的录入，再进行确定操作'})
        }
      },
      backClick() {
        if (this.checkResult()) {
           confirmFn({tipText: '确定处理当前稽查结果?',
            sucFn: (cb) => {
              this.checkSubmit(2, cb)
            }
          })
        } else {
          confirmFn({tipText: '请先进行处理结果的录入，再进行确定操作'})
        }
      }
    },
    components: {
      infoTable,
      picList
    },
    beforeDestroy () {
    },
    created () {
    },
    mounted () {
      this.getDetail()
    },
    activated () {
    },
    computed: {
    }
  }
</script>
<style scoped>
.button{
  text-align: right;
}
.auditRecord, .button{
  padding-top: 20px;
}
.check{
  border: 0;
  width: 98%;
}
.auditHandle{
  padding-top: 20px;
}
</style>
